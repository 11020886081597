<template>
  <div class="main-table">
    <div class="table-responsive">
      <table id="trial" class="table table-bordered">
        <thead>
            <tr>
                <th>Accounts Head</th>
                <th class="text-end">Receipt Amount</th>
            </tr>
        </thead>

        <tbody>
          <tr>
            <td><strong>Opening Balance</strong></td>
            <td class="text-end"><strong>{{ commaFormat(openingClosingBalances.opening.total) }}</strong></td>
          </tr>

          <template v-if="balances.receipt.length > 0">
            <tr>
              <td :colspan="2" style="background:rgb(209 218 227)"><strong>Receipt</strong></td>
            </tr>
            <tr v-for="(item, i) in balances.receipt" :key="i">
              <td>{{ item.account_head_name }}</td>
              <td class="text-end">{{ commaFormat(item.total) }}</td>
            </tr>
            <tr>
              <td><strong>Total Receipt</strong></td>
              <td class="text-end"><strong>{{ commaFormat(balances.receipt_total.total) }}</strong></td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="2" class="receipt-bg"><strong>Receipt</strong></td>
            </tr>
            <tr>
              <td><strong>Total Receipt</strong></td>
              <td class="text-end"><strong>0</strong></td>
            </tr>
          </template>

          <template v-if="balances.contra_receipt.length > 0">
            <tr>
              <td :colspan="2" style="background:rgb(209 218 227)"><strong>Contra Receipt</strong></td>
            </tr>
            <tr v-for="(item, i) in balances.contra_receipt" :key="i">
              <td>{{ item.account_head_name }}</td>
              <td class="text-end">{{ commaFormat(item.total) }}</td>
            </tr>
            <tr>
              <td><strong>Total Contra Receipt</strong></td>
              <td class="text-end"><strong>{{ commaFormat(balances.contra_receipt_total.total) }}</strong></td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="2" style="background:rgb(209 218 227)"><strong>Contra Receipt</strong></td>
            </tr>
            <tr>
              <td></td>
              <td><strong>Total Contra Receipt</strong></td>
              <td class="text-end"><strong>0</strong></td>
            </tr>
          </template>

          <tr>
            <td><strong>Total</strong></td>
            <td class="text-end">
              <strong>{{ commaFormat(openingClosingBalances?.opening?.total + (balances.hasOwnProperty("receipt_total") ? balances.receipt_total.total : 0) + (balances.hasOwnProperty("contra_receipt_total") ? balances.contra_receipt_total.total : 0)) }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="table-responsive">
      <table id="trial" class="table table-bordered">
        <thead>
            <tr>
                <th>Accounts Head</th>
                <th class="text-end">Payment Amount</th>
            </tr>
        </thead>

        <tbody>
          <tr>
            <td><strong>Opening Balance</strong></td>
            <td class="text-end"><strong>{{ commaFormat(openingClosingBalances.opening.total) }}</strong></td>
          </tr>
          <template v-if="balances.payment.length > 0">
            <tr>
              <td :colspan="2" style="background:rgb(209 218 227)"><strong>Payment</strong></td>
            </tr>
            <tr v-for="(item, i) in balances.payment" :key="i">
              <td>{{ item.account_head_name }}</td>
              <td class="text-end">{{ commaFormat(item.total) }}</td>
            </tr>
            <tr>
              <td><strong>Total Payment</strong></td>
              <td class="text-end"><strong>{{ commaFormat(balances.payment_total.total) }}</strong></td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="2" style="background:rgb(209 218 227)"><strong>Payment</strong></td>
            </tr>
            <tr>
              <td><strong>Total Payment</strong></td>
              <td class="text-end"><strong>0</strong></td>
            </tr>
          </template>

          <template v-if="balances.contra_payment.length > 0">
            <tr>
              <td :colspan="2" style="background:rgb(209 218 227)"><strong>Contra Payment</strong></td>
            </tr>
            <tr v-for="(item, i) in balances.contra_payment" :key="i">
              <td>{{ item.account_head_name }}</td>
              <td class="text-end">{{ commaFormat(item.total) }}</td>
            </tr>
            <tr>
              <td><strong>Total Contra Payment</strong></td>
              <td class="text-end"><strong>{{ commaFormat(balances.contra_payment_total.total) }}</strong></td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="2" style="background:rgb(209 218 227)"><strong>Contra Payment</strong></td>
            </tr>
            <tr>
              <td><strong>Total Contra Payment</strong></td>
              <td class="text-end"><strong>0</strong></td>
            </tr>
          </template>

          <tr>
            <td><strong>Closing Balance</strong></td>
            <td class="text-end"><strong>{{ commaFormat(openingClosingBalances.closing.total) }}</strong></td>
          </tr>

          <tr>
            <td><strong>Total</strong></td>
            <td class="text-end">
              <strong>{{ commaFormat(openingClosingBalances?.closing?.total + (balances.hasOwnProperty("payment_total") ? balances.payment_total.total : 0) + (balances.hasOwnProperty("contra_payment_total") ? balances.contra_payment_total.total : 0)) }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import figureFormatter       from '@/services/utils/figureFormatter'
import {useRoute, useRouter} from "vue-router";

const props = defineProps({
  balances: Object,
  dynamicHeaders: Array,
  openingClosingBalances: Object,
})

const router = useRouter()
const route = useRoute()
const {commaFormat} = figureFormatter ()

function getTotal(headerId, type){
  if(type === "receipt" && (props.balances.hasOwnProperty("receipt_total") || props.balances.hasOwnProperty("contra_receipt_total"))){
    let total = 0;
    if (props.balances.hasOwnProperty("receipt_total") && props.balances.receipt_total.balances[headerId]){
      total += props.balances.receipt_total.balances[headerId];
    }
    if (props.balances.hasOwnProperty("contra_receipt_total") && props.balances.contra_receipt_total.balances[headerId]){
      total += props.balances.contra_receipt_total.balances[headerId];
    }

    if (props.openingClosingBalances.opening.balances.hasOwnProperty(headerId)){
      total += props.openingClosingBalances.opening.balances[headerId];
    }

    return commaFormat(total);
  }else if(type === "payment" && (props.balances.hasOwnProperty("payment_total") || props.balances.hasOwnProperty("contra_payment_total"))){
    let total = 0;
    if (props.balances.hasOwnProperty("payment_total") && props.balances.payment_total.balances[headerId]){
      total += props.balances.payment_total.balances[headerId];
    }

    if (props.balances.hasOwnProperty("contra_payment_total") && props.balances.contra_payment_total.balances[headerId]){
      total += props.balances.contra_payment_total.balances[headerId];
    }

    if (props.openingClosingBalances.closing.balances.hasOwnProperty(headerId)){
      total += props.openingClosingBalances.closing.balances[headerId];
    }

    return commaFormat(total);
  }
}
</script>

<style scoped>
.receipt-bg {
    background:rgb(209 218 227)
}
.main-table {
    display: flex;
    min-height: 200px;
    justify-content: space-between;
}
.table-responsive {
    width: 48%;
}
#trial td{
  padding: 2px
}
#trial th{
  padding: 10px 3px;
}
</style>
  