<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="card p-2">
      <TitleButton
          :showBtn="false"
          :title="isDailyCashSummary ? 'Daily Receipt & Payment' : 'Statement of receipts and payments'"
      />

      <div class="px-2" v-if="!isDailyCashSummary">
        <DateQuerySetter
            btnTitle="Go"
            @onClickGo="onClickGo"
        >
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label for="colFormLabel" class="col-form-label">Business</label>
            <v-select
                placeholder="Select Business"
                class="w-100"
                :options="businesses"
                label="name"
                :reduce="(name) => name.id"
                v-model="business_id"
            />
          </div>
          <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
            <label for="colFormLabel" class="col-form-label">Project</label>
            <v-select
                placeholder="Select Project"
                v-model="project_id"
                :options="projects"
                label="name"
                :reduce="name => name.id"
                @option:selected="onSelectProject(true)"
            />
          </div>
          <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
            <label for="colFormLabel" class="col-form-label">Donors</label>
            <v-select
                placeholder="Select Donor"
                class="w-100"
                :options="donors"
                label="name"
                :reduce="name => name.id"
                v-model="donor_id"
            />
          </div>
          <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
            <label for="colFormLabel" class="col-form-label">Cost Centre</label>
            <v-select
                placeholder="Select Cost Centre"
                v-model="cost_centre_id"
                :options="costCentres"
                label="name"
                :reduce="name => name.id"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="d-flex align-items-center justify-content-between filter-label">
                <label for="colFormLabel" class="col-form-label">Party: </label>
                <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                <i class="fas fa-search"></i>
            </span>
            </div>

            <AsyncSelect
                placeholder="Select Party"
                v-model="contactProfile"
                :api-service="fetchContactProfiles"
                :format-label="formatPatientLabel"
            />
          </div>
        </DateQuerySetter>
      </div>
    </div>

    <div class="card px-2">
      <div class="col-12 mb-1" v-if="!isDailyCashSummary">
        <div class="d-flex justify-content-end px-2">
          <button
              @click="exportTable"
              class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
          >
            Export
          </button>
        </div>
      </div>

      <ReceiptsPaymentsReportTableShort
          v-if="isDailyCashSummary"
          :balances="receiptsPaymentsData"
          :dynamicHeaders="dynamicHeaders"
          :openingClosingBalances="openingClosingBalances"
      />
      <ReceiptsPaymentsReportTable
          v-else
          :balances="receiptsPaymentsData"
          :dynamicHeaders="dynamicHeaders"
          :openingClosingBalances="openingClosingBalances"
      />
      <ContactDropdownFilter
        v-if="store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        :isFilterable="true"
        :companyRoles="companyRoles"
      />
    </div>

    <GlobalLoader />
  </div>
</template>

<script setup>
import handleAccounting from '@/services/modules/accounting'

import {computed, inject, onMounted, ref} from 'vue'
import TitleButton                 from '@/components/atom/TitleButton'
import DateQuerySetter             from '@/components/atom/DateQuerySetter'
import ReceiptsPaymentsReportTable from '@/components/molecule/company/receipts-payments-report/ReceiptsPaymentsReportTable'
import ReceiptsPaymentsReportTableShort from '@/components/molecule/company/receipts-payments-report/ReceiptsPaymentsReportTableShort'
import handleCBusinesses from "@/services/modules/businesses";
import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleContact from '@/services/modules/contact'

import {useRoute, useRouter}       from "vue-router";
import {useStore}                  from "vuex";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import Pagination from "@/components/atom/Pagination.vue";
import handleRole from "@/services/modules/role";

const router      = useRouter()
const route       = useRoute()
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const store       = useStore();
const token = localStorage.getItem('token');

const { fetchProjects } = handleProjects()
const { fetchCostCentreList } = handleCostCentres()
const { fetchBusinessList } = handleCBusinesses();
const { fetchContactProfiles } = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();

const businesses = ref([])
const projects = ref([])
const donors = ref([])
const costCentres = ref([])
const contactProfiles = ref([])

const project_id = ref(null)
let donor_id = ref(null)
const cost_centre_id = ref(null)
const business_id = ref(null)
const contactProfile = ref(null)
const companyRoles = ref([])

let receiptsPaymentsData = ref({
  receipt : [],
  contra_receipt : [],
  payment : [],
  contra_payment : []
});
let dynamicHeaders = ref([]);
let openingClosingBalances = ref({
  opening : [],
  closing : []
});
let loader = ref(false)

const { getReceiptsPayments } = handleAccounting()
const { fetchCompanyDefaultRoles } = handleRole();

const companyId = computed(() => {
  return route.params.companyId
})
const start = computed(() => {
  return route.query.start
})
const end = computed(() => {
  return route.query.end
})

const isDailyCashSummary = computed(() => route.name === "daily-cash-summary")

function onClickGo() {
  getReceiptsPaymentsData(getQuery())
}

function exportTable () {
  let url = `${process.env.VUE_APP_BASE_URL}/export/accounting/receipts-payments` + getQuery() + `&_token=${token}`;
  let a = document.createElement("a")
  a.setAttribute("href", url)
  a.click()
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  if (!start.value && !end.value) return query
  if (start.value) query += '&start_date=' + start.value
  if (end.value) query += '&end_date=' + end.value
  if (business_id.value) query += "&business_id=" + business_id.value;
  if (project_id.value) query += "&project_id=" + project_id.value;
  if (donor_id.value) query += "&donor_id=" + donor_id.value;
  if (cost_centre_id.value) query += "&cost_centre_id=" + cost_centre_id.value;
  if (contactProfile.value) query += "&contact_profile_id=" + contactProfile.value.id;
  return query
}

function getReceiptsPaymentsData(query = '') {
  loader.value = true;
  getReceiptsPayments(query)
  .then(function (res) {
    if (!res.status) {
      showError(res.message);
      receiptsPaymentsData.value = {
        receipt : [],
        contra_receipt : [],
        payment : [],
        contra_payment : []
      };
      dynamicHeaders.value = [];
      openingClosingBalances.value = {
        opening : [],
        closing : []
      };
    }
    if (res.status) {
      receiptsPaymentsData.value = res.data.data;
      dynamicHeaders.value = res.data.dynamic_headers;
      openingClosingBalances.value = res.data.opening_closing_balances;
    }
    loader.value = false
  })
  .catch(function (err) {
    loader.value = false
    if (!err.response) {
      showError('Something is wrong. Check your connectivity!!')
    }
    if (err.response?.data) {
      showError(err.response.data.message)
    }

    receiptsPaymentsData.value = {
      receipt : [],
      contra_receipt : [],
      payment : [],
      contra_payment : []
    };
    dynamicHeaders.value = [];
    openingClosingBalances.value = {
      opening : [],
      closing : []
    };
  })
}

function onSelectProject (resetDonor = false)  {
  donors.value = [];
  if (resetDonor) donor_id.value = null;
  for (let project of projects.value) {
    if (project.id === project_id.value && project.party.hasOwnProperty('id')) {
      donors.value.push(project.party);
      break;
    }
  }
}

function onOpenContactSearchModal () {
  store.state.isModalOpenThree = true;
}


function onSearchContact (contact) {
  contactProfile.value = contact
}

onMounted(async () => {
  getReceiptsPaymentsData(getQuery());
  let q = '';
  let contactId = '';
  const companyQuery = `?company_id=${companyId.value}`
  const projectQuery = companyQuery + '&with_donors=1';
  const contactQuery = companyQuery + `&q=${q}&contact_id${contactId}`;

  Promise.all([
    fetchBusinessList(companyQuery).then(res => {
      if(res.data) {
        businesses.value = res.data
      }
    }),
    fetchProjects(projectQuery).then(res => {
      if(res.data) {
        projects.value = res.data
      }
    }),
    fetchCostCentreList(companyQuery).then(res => {
      if(res.data) {
        costCentres.value = res.data
      }
    }),
    fetchContactProfiles(contactQuery).then(res => {
      if(res.data) {
        contactProfiles.value = res.data
      }
    }),
    fetchCompanyDefaultRoles(companyQuery).then(res => {
      if (res.status) {
        companyRoles.value = res.data
      }
    })
  ]).then(() => {
    loader.value = false;
  }).catch(err => {
    loader.value = false;
    this.showError(err.message)
  }).finally(() => {
    loader.value = false;
  })
})
</script>
